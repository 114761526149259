import { getCookie } from './cookies';
import { Token } from './types/const';

export function checkArray(
  input: string[] | any,

  target: string[] | any,
) {
  return input.every(item => target.includes(item));
}
export const auth = () => !!getCookie(Token);
