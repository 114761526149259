import { createGlobalStyle } from 'styled-components';
const styled = { createGlobalStyle };
export const GlobalStyle = styled.createGlobalStyle`
  body {
    height: 100vh;
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 100%;
  }

  :root {
    --color-primary: #e8106f;
    --color-green: #5ec269;
    --color-grey-0: #ffffff;
    --color-grey-1: #eeeeee;
    --color-grey-2: #cccccc;
    --color-grey-3: #999999;
    --color-grey-4: #666666;
    --color-grey-5: #333333;
    --color-grey-6: #000000;
    --color-red: #de5650;
    --color-blue: #3c82f6;
    --font-body: 'Inter', sans-serif;
  }

  * {
    margin: 0;
    padding: 0;
  }

  html {
    scroll-behavior: smooth;
    font-size: 14px;
  }

  body {
    font-family: var(--font-body);
    background: var(--color-grey-1);
  }

  strong {
    font-weight: 600;
  }

  ul {
  }
  li {
    list-style: none;
  }
  /* .container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  } */
  .font-24 {
    font-size: 24px;
  }
  a {
    text-decoration: none;
    color: var(--color-primary);
    &:hover {
      color: #9d0146;
    }
  }
  .bg-grey-1 {
    background-color: var(--color-grey-1) !important;
  }

  .ant-input {
    &-focused {
    }
  }

  header {
    position: fixed;
    z-index: 999;
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    background: var(--color-grey-0);
    padding: 10px 0;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .-wrapper {
      .-img {
        height: 45px;
        width: 45px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 5px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .--logo {
      cursor: pointer;
      img {
        width: 160px;
        height: auto;
      }
    }

    @media (max-width: 640px) {
      .logo_header {
        img {
          width: 100px;
        }
      }
    }
  }
  .segTitle {
    margin-bottom: 2rem;
    text-align: center;
    h2 {
      text-align: center;
      font-size: 2.5rem;
      font-weight: 700;
    }
    .--subTitle {
      font-size: 1.5rem;
      margin: 0px;
      font-weight: 400;
    }
    @media (max-width: 640px) {
      h2 {
        font-size: 2rem;
      }
      .--subTitle {
        font-size: 1rem;
      }
    }
  }
  .headerLogin {
    align-items: center;
    gap: 8px;
    align-items: center;
    height: 45px;
  }
  .headerCredential {
    color: var(--color-green);
    display: flex;
    i {
      font-weight: 900;
    }
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    background: rgba(94, 194, 105, 0.1);
    &.notActive {
      background: rgb(222 86 80 / 10%);
      color: var(--color-red);
    }
    @media (max-width: 640px) {
    }
  }

  /* input{
    transition: all 0.5s ease;
    &:focus{
        border: 1px solid var(--color-primary) !important;
        outline: none;
    }
} */
  .search_header {
    position: relative;

    i {
      position: absolute;
      color: var(--color-grey-4);
      left: 13px;
      transform: translate(0, -50%);
      top: 50%;
    }

    input {
      height: 44px;
      display: flex;
      align-items: center;
      border-radius: 25px;
    }

    button {
      position: absolute;
      color: var(--color-grey-3);
      right: 13px;
      transform: translate(0, -50%);
      top: 50%;
      background: var(--color-grey-0);
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      border: none;
      padding: 4px;
      font-size: 12px;
      cursor: pointer;
    }

    @media (max-width: 640px) {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-grey-1);
      border-radius: 50%;
      font-size: 13px;

      i {
        position: relative;
        left: unset;
        top: unset;
        transform: none;
      }

      .submit_search {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: calc(100% + 10px);
        opacity: 0;
        pointer-events: none;
        transition: all 0.5s ease;

        &.active {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }

  .list_project {
    .--wrapper {
      background: var(--color-grey-0);
      border: 1px solid var(--color-grey-1);
      box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
    }
    .thead_proj {
      span {
        font-weight: 600;
      }
      .number {
        background: var(--color-grey-1);
        border-radius: 6px;
        padding: 5px 12px;
      }
    }

    .all_project {
      .item {
        border-bottom: 1px solid var(--color-grey-1);
        padding: 12px 0;
        font-size: 14px;

        .name {
          a {
            font-weight: 600;
            margin-left: 14px;
            color: var(--color-grey-6);
            transition: color 0.5s ease;

            &:hover {
              color: var(--color-primary);
            }
          }

          &.icon_green {
            i {
              color: var(--color-green);
            }
          }

          &.icon_red {
            i {
              color: var(--color-red);
            }
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .infor_proj {
        color: var(--color-grey-3);

        .time {
        }

        .folder {
          margin-right: 24px;
        }

        .file,
        .folder {
          i {
            margin-left: 8px;
          }
        }

        .actions {
          a {
            padding: 0 11px;
            color: var(--color-grey-3);
            transition: color 0.5s ease;

            &:hover {
              color: var(--color-primary);
            }
          }
        }
      }
    }

    @media (max-width: 640px) {
      width: 100%;
      .--wrapper {
        padding: 16px !important;
        margin-bottom: 0 !important;
      }
      .thead_projct {
        justify-content: start !important;
        gap: 12px;
      }
      .search_header {
        width: max-content;
        height: auto;
      }
      .thead_proj {
        .--top {
          gap: 12px;
          flex-flow: column;
          align-items: flex-start !important;
        }
      }
      .bucket-link {
        position: relative !important;
        bottom: unset;
      }
      .all_project {
        .item {
          flex-flow: column;
          align-items: flex-start !important;
        }
        .infor_proj {
          justify-content: space-between;
          display: flex;
          width: 100%;
          padding-left: 28px;
        }
      }
    }
  }

  .btn_theme_custom {
    background: var(--color-primary);
    border-radius: 8px;
    padding: 10px 12px;
    color: #fff;
    transition: transform 0.75s ease;
    border: none;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #e8106f6b;
    }
  }

  .page_detail {
    .content_detail {
      width: 50%;

      padding: 16px 20px;

      & > .wrapper {
        background: white;
        height: 100%;
      }
    }

    .select_box {
      display: flex;
      align-items: center;

      & > div {
        margin-right: auto;
      }

      a {
        color: var(--color-grey-4);
        text-decoration: underline;
      }
    }

    .search_detail,
    .wiget_detail {
      background: var(--color-grey-0);
      width: 25%;
      padding: 16px 20px;
    }

    .search_detail {
      .search_header {
        width: 100%;

        input {
          width: calc(100% - 53px);
        }
      }

      .icon {
        cursor: pointer;
        background: var(--color-grey-0);
        border: 1px solid var(--color-grey-1);
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        color: var(--color-grey-5);
        flex: 0 0 auto;
      }

      .select_box {
        margin: 21px 0;
        display: flex;
        justify-content: flex-end;

        a {
          margin-right: 5px;
        }
      }
    }

    .wiget_detail {
      position: fixed;
      right: 0;
      height: calc(100vh - 61px);

      .btn_custom_grey {
        cursor: pointer;

        margin-bottom: 16px;
        font-weight: 600;
        font-size: 15px;
      }

      ul {
        list-style: none;

        li {
          margin-bottom: 16px;

          span {
            color: var(--color-grey-3);
            font-size: 12px;
          }

          p {
            color: var(--color-grey-5);
          }
        }
      }
    }

    @media (max-width: 640px) {
      flex-flow: column;
      align-items: center;
      padding-top: 30px;

      .search_detail,
      .content_detail,
      .wiget_detail {
        width: 85%;
      }

      .content_detail {
        margin: 15px 0;
      }

      .search_detail {
        .search_header {
          width: 100%;
          height: auto;
          background: none;

          i {
            position: absolute;
            left: 13px;
          }
        }
      }
    }
  }

  button.btn_custom_grey {
    cursor: pointer;
  }

  .btn_custom_grey {
    cursor: pointer;
    color: var(--color-grey-5);
    background: var(--color-grey-1);
    border-radius: 12px;
    width: max-content;
    padding: 10px;
  }

  #accodition {
    color: var(--color-grey-5);
    font-size: 15px;

    .content {
      padding: 0 18px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;

      .ant-checkbox-wrapper {
        margin-right: 10px;
      }

      i {
        margin-right: 5px;
        color: var(--color-grey-3);
      }

      .item {
        &:first-child {
          margin-top: 0;
        }

        .w-100 {
          cursor: pointer;
          padding: 4px;

          &.active {
            background: var(--color-grey-2);
            /* color: white; */
            padding-left: 8px;
            border-radius: 4px;
            i {
              color: var(--color-grey-4);
            }
          }
        }
      }

      &.active {
        max-height: max-content;
      }
    }

    .collapsible {
      background-color: var(--color-grey-0);
      color: var(--color-grey-5);
      cursor: pointer;
      padding: 10px 0;
      width: 100%;
      border: none;
      text-align: left;
      outline: none;
      font-size: 15px;
      align-items: center;
      justify-content: space-between;

      .ant-checkbox-wrapper {
        margin-right: 10px;
      }

      i {
        margin-right: 5px;
        color: var(--color-grey-3);
      }
    }
  }

  .page_setting {
    .form_setting {
      font-size: 15px;
      width: 35%;
      margin: 35px auto;
      background: var(--color-grey-0);
      padding: 24px 36px;
      border: 1px solid var(--color-grey-1);
      box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
    }

    .segTitle {
      justify-content: space-between;
      margin-bottom: 24px;

      span {
        color: red;
      }
    }

    .form_tooltip {
      display: flex;

      align-items: center;

      p {
        flex: 0 0 25%;
      }

      color: var(--color-green);

      i {
        margin-right: 5px;
      }
    }

    .form_btn {
      display: flex;
      justify-content: space-between;
    }

    #accodition {
      .collapsible {
        font-weight: 600;
        color: var(--color-grey-6);
      }

      .card {
        padding: 15px 0;
        border-bottom: 1px solid var(--color-grey-2);
      }

      .content {
        color: var(--color-grey-4);
        padding: 0;
      }
    }

    @media (max-width: 640px) {
      .form_setting {
        width: 100%;
      }
    }
  }

  .popup_newproj {
    .close {
      position: absolute;
      top: -18px;
      right: -18px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #fff;
      color: #333;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.5s ease;
      border: 1px solid #ddd;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      font-size: inherit;
      opacity: 1;

      &:hover {
        transform: scale(0.9);
      }
    }

    .modal-dialog {
    }

    .modal-content {
      border: none;
    }

    .modal-header {
      border: none;
    }

    .form_news_proj {
      .form_control {
        .ant-form-item {
          margin: 0;
        }
      }

      .label {
        color: var(--color-grey-3);
        margin-bottom: 10px;

        span {
          color: var(--color-primary);
        }
      }

      /* input{
            background: var(--color-grey-0);
            border: 1px solid var(--color-grey-2);
            border-radius: 6px;
            padding: 10px;
            width: calc(100% - 20px);
        } */
      .form_group {
        position: relative;
      }

      .btn_theme_custom {
        width: max-content;
      }
    }
  }
  .input-wrapper {
    padding: 36px 10px;
    border-radius: 5px;
    border: 1px #cccccc dashed;
    &:hover {
      border: 1px var(--color-primary) dashed;
    }
  }
  .input_file {
    padding: 36px 10px;
    display: flex;
    justify-content: center;

    .text_drag {
      display: flex;
      align-items: center;
      color: var(--color-grey-6);

      svg {
        margin-right: 10px;
      }
    }
  }
  .popup_payment {
    z-index: 999999;
    background: rgba(0, 0, 0, 0.4392156863);
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: var(--color-blue);
    }

    .modal-dialog {
      background: var(--color-grey-0);
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      width: 40%;
    }

    .modal-content {
      padding: 36px 24px;
      position: relative;

      .close {
        position: absolute;
        top: -18px;
        right: -18px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #fff;
        color: #333;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease;
        border: 1px solid #ddd;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
        font-size: inherit;

        &:hover {
          transform: scale(0.9);
        }
      }
    }

    .modal-header {
      .modal-title {
        width: 100%;
        justify-content: center;
        align-items: center;
        font-size: 24px;
      }

      img {
        width: 30px;
        margin-right: 10px;
      }
    }

    .price_purchase {
      justify-content: center;
      margin: 36px 0 16px 0;
      align-items: center;

      .old_price {
        color: var(--color-grey-3);
        text-decoration: line-through;
        margin-right: 10px;
      }

      .news_price {
        font-size: 42px;
        color: var(--color-green);
        font-weight: 600;
      }
    }

    .note {
      text-align: center;
      font-size: 15px;
      margin-bottom: 32px;

      span {
        color: var(--color-green);
      }
    }

    ul {
      width: max-content;
      margin: 0 auto 32px auto;
      font-size: 15px;

      li {
        margin-bottom: 16px;
        align-items: center;
      }

      i {
        color: var(--color-green);
        margin-right: 8px;
        font-size: 20px;
      }

      p {
        font-weight: 600;
      }
    }

    article {
      text-align: center;
      line-height: 1.3;

      .text_small {
        color: var(--color-green);
        margin-top: 32px;
      }
    }

    .btn_theme_custom {
      margin: 16px auto 0 auto;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }
  }

  .modal-credential {
    .ant-modal-content {
      border-radius: 16px;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
      padding: 0;
      width: 100%;
      max-width: 480px;
    }
    .--img {
      margin-bottom: 0.5rem;
      img {
        width: 100%;
        height: auto;
      }
    }
    .--info {
      .-hover {
        cursor: pointer;
        &:hover {
          color: var(--color-primary);
        }
      }
      article {
        border-radius: 6px;
        background: rgba(255, 218, 68, 0.2);
        i {
          margin-left: 5px;
        }
        p {
          font-size: 0.9rem;
        }
      }
    }
    .--formWrapper {
      flex-direction: column;

      label {
        font-size: 0.9rem;
        font-weight: 500;
        color: var(--color-grey-4);
        margin: 0;
      }
    }
    .--form {
      /* input{
          background: var(--color-grey-1);
          border-radius: 6px;
          color: var(--color-grey-5);
          border: none;
          width: 100%;
          padding: 10px;
          margin-bottom: 8px;
          border: 1px solid transparent;
      } */
    }
    .--formContent {
      margin-bottom: 8px;
      .ant-form-item {
        margin-bottom: 4px;
      }
    }
    .--login {
      width: 360px;
      height: fit-content;
      h5 {
        font-weight: initial;
      }
      .--badge {
        background: var(--color-primary);
        color: #fff;
        border-radius: 4px;
        padding: 2px 4px;
      }
      .--btn {
        padding: 12px 24px;
        height: fit-content;
        font-weight: 600;
        display: flex;
        text-align: center;
      }
    }

    .--content {
      padding: 2rem;
      .--title {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
      }
      .--status {
        color: #5ec269;
        p {
          display: flex;
          gap: 8px;
          align-items: center;
          &.error {
            color: #de5650;
          }
        }
      }
      .--actions {
        display: flex;
        gap: 8px;
        button {
          width: max-content;
          border-radius: 8px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 10px 14px;
          height: max-content;
          border: none;
          &.btn-cancel {
            color: #333;
            background: var(--Bg, #f3f3f3);
          }
          &.btn-primary {
          }
          i {
          }
          span {
          }
        }
      }
    }
    .-wizard {
      width: 80vw;
      height: 100%;

      & > div {
        flex-direction: column;
        display: flex;
        &:first-child {
          width: 76%;
          & > div {
            padding: 0 10%;
          }
          .top {
            flex: 1;
            padding-top: 36px;
            padding-bottom: 36px;
          }
          .s3-actions {
            padding-top: 24px;
            padding-bottom: 24px;
            border-top: 3px solid #f3f4f6;
          }
        }
      }
    }
  }

  /* common */
  footer {
    padding: 42px 0 24px;
    color: #fff;
    background-color: #333;
    .container {
      /* display: flex; */
      /* justify-content: space-between; */
    }
    .--left {
      width: 100%;
      max-width: 480px;
    }
    .--right {
    }
    .--logo {
      display: block;
      margin: 0 0 1rem 0;
      img {
        width: 160px;
        height: auto;
      }
    }
    .--desc {
    }
    .--socials {
      gap: 24px;
      li {
        a {
          display: flex;
          gap: 8px;
          color: #fff;
          align-items: center;
          i {
            font-size: 2.2rem;
          }
          span {
          }
          small {
            display: block;
            font-size: 0.9rem;
            opacity: 0.5;
            margin-bottom: -2px;
          }
          strong {
            font-weight: initial;
          }
        }
      }
    }
    .--copyright {
      opacity: 0.5;
      background: #000;
      width: max-content;
      padding: 6px 16px;
      border-radius: 10px;
      span {
      }
    }
  }
  .footerLinks {
    .--item {
      h5 {
        font-size: 0.8rem;
        margin-bottom: 8px;
        text-transform: uppercase;
        opacity: 0.5;
        font-weight: initial;
        letter-spacing: 1px;
      }
      ul {
      }
      li {
      }
      a {
        color: #fff;
        display: block;
        padding: 4px 0;
      }
    }
  }
  .homeBuy {
    padding: 4rem 0 6rem;

    .--wrapper {
      background: #fff;
      padding: 4rem;
      border-radius: 20px;
      box-shadow: 0 6px 2rem rgba(0, 0, 0, 0.1);
    }
    h3 {
      font-size: 1.2rem;
      background: var(--color-grey-1);
      width: max-content;
      padding: 8px 20px;
      border-radius: 12px;
      margin: 0 auto 1rem;
      font-weight: 600;
    }
    h2 {
      margin-bottom: 2rem;
    }

    .--coffee {
      font-size: 6rem;
      line-height: 4rem;
      margin-bottom: 2rem;
    }
    .--payment {
      width: 100%;
      a {
        border: none;
        box-shadow: 0 0 16px rgb(232 16 111 / 20%);
        padding: 16px 24px;
        font-weight: 400;
        font-size: 1.2rem;
        margin: 0 auto;
        border-radius: 12px;
        width: 100%;
        display: block;
        text-align: center;
      }
      .--btn-donate {
        background: var(--color-primary);
        color: #fff;
      }
      .--btn-hire {
        background: #fff;
        color: var(--color-primary);
      }
    }
    @media screen and (max-width: 768px) {
      padding: 3rem 0;
      .--wrapper {
        padding: 2rem 1rem;
        box-shadow: none;
      }
    }
  }
  .photo-noti {
    // height: 200px;
    width: 300px;
    .--icon {
      color: var(--color-green);
      background: rgb(94 194 105 / 20%);
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
    }
    .--tag {
      background: var(--color-grey-1);
    font-size: 0.9rem;
    border-radius: 4px;
    padding: 2px 4px;
    font-weight: 600;
    }
    p {
    }
    small {
      color: var(--color-smoke);
    }
    strong {
      color: var(--color-red);
    }
    button {
      background: var(--color-primary)!important;
      color: #fff!important;
      outline: none;
      border: none;
      width: max-content;
      font-weight: 600;
      padding: 8px 24px;
      height: auto;
      margin: 0 auto;
      border-radius: 6px;
      box-shadow: 0 2px 8px rgb(232 16 111 / 30%);
}
    }
  }
  .photo-modal {
    width: 90vw;
    height: 90vh;
    max-width: none;

    .ant-modal-content,
    .ant-modal-body {
      height: 100%;
      overflow: hidden;
      background: none;
      padding: 0;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
`;
