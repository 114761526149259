import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { DetailState } from './types';
import { createRoutine } from 'redux-saga-routines';
import { detailSaga } from './saga';
import { checkArray } from 'utils/helper';

export const initialState: DetailState = {
  loading: false,
  error: false,
  selected: [],

  listObj: undefined,
};
export const DEATAIL_LISTING = createRoutine('detail/list');
const slice = createSlice({
  name: 'detail',
  initialState,
  reducers: {
    toogleSelect: (state, action) => {
      if (Array.isArray(action.payload)) {
        if (!checkArray(action.payload, state.selected)) {
          const notIncludes = action.payload.filter(
            item => !state.selected.includes(item),
          );
          state.selected = [...state.selected, ...notIncludes];
        } else {
          const crr = [...state.selected].filter(
            item => !action.payload.includes(item),
          );

          state.selected = [...crr];
        }
      }
      if (!Array.isArray(action.payload)) {
        if (
          !state.selected.filter(
            item => JSON.stringify(item) === JSON.stringify(action.payload),
          ).length
        ) {
          state.selected = [...state.selected, action.payload];
        } else {
          const crr = [...state.selected].filter(
            item => item !== action.payload,
          );

          state.selected = [...crr];
        }
      }
    },
    clearSelect: state => {
      state.selected = [];
    },
    setLoading: state => {
      state.loading = true;
    },
    setError: state => {
      state.error = true;
    },
    setObjList: (state, actions) => {
      state.loading = false;
      state.listObj = actions.payload;
    },
    setObjKey: (state, actions) => {
      state.objKey = actions.payload;
    },
    setInfo: (state, actions) => {
      state.loading = false;
      state.objInfo = actions.payload;
    },
    clearData: state => {
      state.listObj = undefined;
      state.objInfo = undefined;
      state.objKey = undefined;
      state.selected = [];
    },
  },
  extraReducers: {
    [DEATAIL_LISTING.TRIGGER]: state => {
      state.loading = true;
    },
    [DEATAIL_LISTING.SUCCESS]: state => {
      state.loading = false;
    },
    [DEATAIL_LISTING.FAILURE]: state => {
      state.error = true;
    },
  },
});
export const { actions: detailActions } = slice;
export const useDetailSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: detailSaga });
  return { actions: slice.actions };
};
