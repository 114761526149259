/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

import { Layout } from './pages/Layout/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { Home } from './pages/Home/Loadable';

import { GlobalStyle } from 'styles/global-styles';
import { Details } from './pages/Details/Loadable';
import { Intro } from './pages/Intro/Loadable';
import { ProtectedRoute } from './components/ProtectedRoute';
import { useSelector } from 'react-redux';
import { isSetting } from './pages/Layout/slice/selector';
import ReactGA from 'react-ga4';

export function App() {
  const { i18n } = useTranslation();
  const setting = useSelector(isSetting);
  ReactGA.initialize(process.env.REACT_APP_GA_KEY || '');
  return (
    <BrowserRouter>
      <Helmet
        defaultTitle="Panoee S3 hosting"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A AWS S3 platform" />
      </Helmet>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={
              <ProtectedRoute
                auth={setting}
                navigate="/intro"
                children={<Outlet />}
              />
            }
          >
            <Route index element={<Home />}></Route>
            <Route path="detail/:id" element={<Details />}></Route>
          </Route>
          {/* <Route path="setting" element={<Setting />}></Route> */}

          <Route
            path="/intro"
            element={
              <ProtectedRoute
                auth={!setting}
                navigate="/"
                children={<Intro />}
              />
            }
          ></Route>
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}
