import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'app/pages/Details/slice';
import { RootState } from 'types';

const globalState = (state: RootState) => state?.global || initialState;
export const bucketName = createSelector([globalState], state => state.Bucket);
export const BucketList = createSelector(
  [globalState],
  state => state.BucketList,
);

export const accessKeyId = createSelector(
  [globalState],
  state => state.accessKeyId,
);
export const secretAccessKey = createSelector(
  [globalState],
  state => state.secretAccessKey,
);
export const region = createSelector([globalState], state => state.region);
export const errorState = createSelector([globalState], state => state.error);
export const loadingState = createSelector(
  [globalState],
  state => state.loading,
);

export const isSetting = createSelector(
  [globalState],
  state => state.isSetting,
);
export const islogin = createSelector([globalState], state => state.islogin);
export const avatarUrl = createSelector(
  [globalState],
  state => state.avatarUrl,
);
