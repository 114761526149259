/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import 'filepond/dist/filepond.min.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';
import { ConfigProvider, ThemeConfig } from 'antd';
export const store = configureAppStore();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const config: ThemeConfig = {
  token: {
    colorPrimary: '#E8106F',
    fontFamily: 'Inter',
  },
  components: {
    Input: {
      colorBgContainer: '#EEE',
      colorBorder: 'rgba(0, 0, 0, 0)',
      controlHeight: 38,
    },
    Select: {
      colorBgContainer: '#EEE',
      colorBorder: 'rgba(0, 0, 0, 0)',
      controlHeight: 38,
    },
  },
};
root.render(
  <Provider store={store}>
    <HelmetProvider>
      {/* <React.StrictMode> */}
      <ConfigProvider theme={config}>
        <App />
      </ConfigProvider>

      {/* </React.StrictMode> */}
    </HelmetProvider>
  </Provider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
